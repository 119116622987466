/*
  管理团队组件
*/
import { useReactive, useMount } from 'ahooks'

// 组件样式
import css from '@/assets/style/pages/index/group.less'

import { getHomeInfo } from '@/api/index'
const Group = () => {
  const state = useReactive({
    list:[]
  })
  const methods = useReactive({
    handleHomeInfo() {
      const params = {
        sectionId: 2,
      }
      getHomeInfo(params).then(res => {
        state.list = res.data
      })
    },
  })
  useMount(() => {
    methods.handleHomeInfo()
  })
  return(
    <div className={css.groupWrap} id="group">
      <div className={`${css.groupTitle}`}>管理团队</div>
        <div className={css.listBox}>
          {
            state.list.map((item,index) => {
              return(
                <div key={index} className={`${css.listDiv}`}>
                  <img className={css.listImg} src={item.img} alt="" />
                  <div className={css.listView}>
                    <div className={css.listName}>{item.name}</div>
                    <div className={css.listTitle}>{item.text}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
    </div>
  )
}

export default Group