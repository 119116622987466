//把参数转换成拼接字符串的形式
export const _paramsToQueryString = (params) => {
    return Object.keys(params).map(key => key + "=" + params[key]).join("&")
}
// 解析地址栏 ? 号后的参数
export const _getQueryVariable = (searchIn, variable) => {
    if (!searchIn || !variable) {
        return null;
    }
    const query = searchIn.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return null;
}