import { Fragment } from 'react'
import { useReactive } from 'ahooks'
// Tab栏
import Tab from '@/components/private/tab'
// 企业目标组件
import Target from '@/pages/index/target.jsx'
// 服务理念组件
import ServeConcept from '@/pages/index/serveConcept.jsx'
// 核心产品
import CoreProduct from '@/pages/index/coreProduct.jsx'
// 管理团队
import Group from '@/pages/index/group.jsx'
// 管理团队
import NewsBox from '@/pages/index/newsBox.jsx'

import { getSection } from '@/api/index'
const Index = ()=>{
    const state = useReactive({
        tabData:[
            {
                name: '核心价值观',
                inx: '0',
                id:'target'
            }, {
                name: '服务理念',
                inx: '1',
                id:'serve'
            }, {
                name: '核心产品',
                inx: '2',
                id:'core'
            }, {
                name: '管理团队',
                inx: '3',
                id:'group'
            }, {
                name: '新闻资讯',
                inx: '4',
                id:'news'
            }
        ]
    })
    const methods = useReactive({
      handleSection() {
        const params = {
          id: 1
        }
      },
    })
    return(
        <Fragment>
            {/* Tab栏 */}
            <Tab tabData={state.tabData}></Tab>
            {/* 企业目标 */}
            <Target></Target>
            {/* 服务理念 */}
            <ServeConcept></ServeConcept>
            {/* 核心产品 */}
            <CoreProduct></CoreProduct>
            {/* 管理团队 */}
            <Group></Group>
            {/* 新闻资讯 */}
            <NewsBox></NewsBox>
        </Fragment>
    )
}

export default Index