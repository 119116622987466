import React from 'react'
import { Fragment } from 'react'
import store from '@/redux/store'
// 路由
import { useHistory } from "react-router-dom"
import { useReactive, useMount } from 'ahooks'
import { getSysConfig } from '@/api/index'
// 组件样式
import css from '@/assets/style/components/foot.less'
// 导航栏数据
import { tabData } from '@/utils/routerData'
// 引入本地图片
import qrcode from '@/assets/img/QRcode.png'
import logo from '@/assets/img/logo.png'
import filingsIcon from '@/assets/img/filings-icon.png'
// js处理包
import { _setPagesKey, _getPagesKey } from '@/utils/storage'

const Foot = () => {
  const history = useHistory()
  const state = useReactive({
    contactUs: [],
  })
  const methods = useReactive({
    handleSysConfig() {
      const params = {
        type: 2
      }
      getSysConfig(params).then(res => {
        state.contactUs = res.data.filter(item => item.KeyName !== '官⽹')
      })
    },
    skipPages(path, key, index) {
      state.current = path
      if (path.indexOf('/') < 0) {
        message.info('暂未开放')
        return
      }
      localStorage.setItem('path', path)
      // 储存导航栏标识
      _setPagesKey(state.current)
      // 判断 index 是否有值
      if (index !== undefined) {
        /*
            判断点击后页面是否滑动
            isSlide：true/false
        */
        if (tabData[key].children[index].state.isSlide) {
          if (tabData[key].children[index].state.id) {
            // 携带参数跳转
            history.push(path, {
              id: tabData[key].children[index].state.id
            })
            let time = setInterval(() => {
              if (document.getElementById(`${tabData[key].children[index].state.id}`)) {
                // 定位到该 id 中的元素
                document.getElementById(`${tabData[key].children[index].state.id}`).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                clearInterval(time);
              }
            })
          } else {
            // 不携带参数跳转
            history.push(path)
          }
        } else {
          let type = tabData[key].children[index].state.pageType
          let data = tabData[key].children[index].state.inx
          localStorage.setItem('inx', data)
          store.dispatch({ type, data })
          // 不携带参数跳转
          history.push(path)
          scrollTo(0, 0);
        }

      } else {
        // 不携带参数跳转
        history.push(path)
        scrollTo(0, 0);
      }
    }
  })
  useMount(() => {
    methods.handleSysConfig()
  })
  return (
    // 外包围及背景图
    <footer className={css.footWrap}>
      {/* 遮罩层及整体 */}
      <div className={css.footBox}>
        {/* 联系我们/关于我们 */}
        <div className={css.footView}>
          <div className={css.leftDiv}>
            {
              tabData.map((item, index) => {
                if (index !== 0) {
                  return (
                    <div key={item.id} className={css.routeBox}>
                      <p onClick={() => methods.skipPages(item.path, index)} style={{ marginBottom: '20px', fontSize: '16px' }}>{item.name}</p>
                      {
                        item.children.map((itemChild, inx) => {
                          return (
                            <p key={inx} onClick={() => methods.skipPages(itemChild.path, index, inx)} style={{ fontSize: '14px' }}>{itemChild.name}</p>
                          )
                        })
                      }
                    </div>
                  )
                } else {
                  return <Fragment key={item.id} />
                }
              })

            }
          </div>
          {/* 右边-联系我们 */}
          <div className={css.footDiv}>
            {/* 名称 */}
            {/* <div className={css.footTitle}>联系我们</div> */}
            <img className={`${css.footTitle} ${css.logo}`} src={logo} alt=""></img>
            {/* 列表数据 */}
            {
              state.contactUs.map((item, index) => {
                return (
                  <div key={index} className={css.leftBox}>
                    {/* 小图标 */}
                    <img className={css.icon} src={item.ImgUrl} alt=""></img>
                    {/* 文本信息 */}
                    <div className={css.text}>{item.KeyName}：{item.KeyValue}</div>
                  </div>
                )
              })
            }
          </div>
          {/* 右边-关于我们 */}
          {/* <div className={css.footDiv}>
                        <div className={css.footTitle}>关于我们</div>
                        <div className={css.title}>运服通将致力于解决企业货主与承运司机的货运匹配；解决企业货主难以取得合法合规增值税运输专用发票的痛点问题，具有极大的税收贡献和社会价值</div>
                        <img
                            className={css.codeIcon}
                            alt=""
                            src={qrcode}></img>
                    </div> */}
          {/* 二维码 */}
          <img
            className={css.codeIcon}
            alt=""
            src={qrcode}></img>
        </div>

        {/* 版权 */}
        <div className={css.copyright} style={{ display: "flex",justifyContent: "center",alignItems: "center" }}>© 版权所有 运服通 · 2023方众数字科技提供技术支持 <a rel="noreferrer" href="https://beian.miit.gov.cn/">赣ICP备2021002171号</a>
          <img
            className={css.icon}
            alt=""
            src={filingsIcon} style={{ margin: "0px 2px 0px 5px" }}></img><a rel="noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36010402000483">赣公网安备 36010402000483号</a>
        </div>
        {/* filings-icon */}
      </div>
    </footer>
  )
}

export default Foot