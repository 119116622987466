// 导航栏路由数据
export const tabData = [
  {
    id: "0",
    name: "首页",
    path: "/index",
    component: "pages/index/index",
    children: [],
  },
  {
    id: "1",
    name: "关于我们",
    path: "/about",
    children: [
      {
        path: "/about",
        name: "平台简介",
        component: "pages/about/about",
        state: {
          id: "terrace",
          isSlide: true,
        },
      },
      {
        path: "/about?id=culture",
        name: "企业文化",
        component: "pages/about/about",
        state: {
          id: "culture",
          isSlide: true,
        },
      },
      {
        path: "/about?id=honor",
        name: "荣誉资质",
        component: "pages/about/about",
        state: {
          id: "honor",
          isSlide: true,
        },
      },
      {
        path: "/about?id=partner",
        name: "合作伙伴",
        component: "pages/about/about",
        state: {
          id: "partner",
          isSlide: true,
        },
      },
      {
        path: "/about?id=join",
        name: "加入我们",
        component: "pages/about/about",
        state: {
          id: "join",
          isSlide: true,
        },
      },
    ],
  },
  {
    id: "2",
    name: "解决方案",
    path: "/solution",
    children: [
      {
        path: "/solution",
        name: "网络货运",
        component: "pages/solution/solution",
        state: {
          pageType: "network",
          isSlide: false,
          inx: "0",
        },
      },
      {
        path: "/solution?id=tax",
        name: "税务代征",
        component: "pages/solution/solution",
        state: {
          pageType: "tax",
          isSlide: false,
          inx: "1",
        },
      },
    ],
  },
  {
    id: "3",
    name: "商务合作",
    path: "/agent",
    children: [
      {
        path: "/agent",
        name: "代理商合作",
        component: "pages/business/agent",
        state: {
          pageType: "agentCooperation",
          isSlide: false,
          inx: "0",
        },
      },
      // {
      //     path:'9',
      //     name:'服务商合作'
      // },{
      //     path:'10',
      //     name:'政府合作'
      // }
    ],
  },
  {
    id: "4",
    name: "产品中心",
    path: "/product",
    component: "pages/product/product",
    state: {
      pageType: "transport",
      isSlide: false,
      inx: "0",
    },
    children: [
      // {
      //   path: '/product',
      //   name: '运税宝',
      //   component: 'pages/product/product',
      //   state: {
      //     pageType: 'transport',
      //     isSlide: false,
      //     inx: '0',
      //   }
      // }, {
      //   path: '/product?id=express',
      //   name: '工运宝',
      //   component: 'pages/product/product',
      //   state: {
      //     pageType: 'express',
      //     isSlide: false,
      //     inx: '1',
      //   }
      // }, {
      //   path: '/product?id=transports',
      //   name: '速运宝',
      //   component: 'pages/product/product',
      //   state: {
      //     pageType: 'transports',
      //     isSlide: false,
      //     inx: '2',
      //   }
      // },
    ],
  },
  {
    id: "5",
    name: "新闻资讯",
    path: "/newsInformation",
    children: [
      {
        path: "/newsInformation",
        name: "行业新闻",
        component: "pages/index/newsList/newsInformation",
        state: {
          pageType: "news",
          isSlide: false,
          inx: "0",
        },
      },
      {
        path: "/newsInformation?id=company",
        name: "公司新闻",
        component: "pages/index/newsList/newsInformation",
        state: {
          pageType: "company",
          isSlide: false,
          inx: "1",
        },
      },
      {
        path: "/newsInformation?id=notice",
        name: "公告信息",
        component: "pages/index/newsList/newsInformation",
        state: {
          pageType: "notice",
          isSlide: false,
          inx: "2",
        },
      },
      {
        path: "/newsInformation?id=product",
        name: "产品资讯",
        component: "pages/index/newsList/newsInformation",
        state: {
          pageType: "product",
          isSlide: false,
          inx: "3",
        },
      },
    ],
  },
  {
    id: "6",
    name: "联系我们",
    path: "/contact",
    component: "pages/contact/contact",
    children: [],
  },
];

// 其他页面路由数据
export const pageData = [
  {
    path: "/newsInformation",
    name: "新闻资讯",
    component: "pages/index/newsList/newsInformation",
  },
  {
    path: "/newsInfo",
    name: "新闻详情",
    component: "pages/index/newsInfo/newsInfo",
  },
  {
    path: "/productCenter",
    name: "方众产品合计",
    component: "pages/productCenter/productCenter",
  },
];
