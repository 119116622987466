// import WOW from 'wowjs';
import { useEffect } from "react";
// 设置Tab导航栏状态
import { _setPagesKey } from "@/utils/storage";
/*
  路由：
  Switch：只显示匹配到的第一个路由
  Route：路由标签 
*/
import { Switch, Route } from "react-router-dom";
// 获取tab栏数据
import { tabData, pageData } from "@/utils/routerData";
// 动态加载路由
import loadCmpt from "@/utils/LoadCmpt";

// 顶部组件
import Head from "@/components/common/head";
// 底部组件
import Foot from "@/components/common/foot";
// 首页
import Index from "@/pages/index/index.js";

function App() {
  useEffect(() => {
    // 动画加载
    // new WOW.WOW().init();
    // 关闭窗口后重新定义导航栏的KEY
    _setPagesKey("index");
  }, []);
  return (
    <div className="App" style={{ width: "100%", height: "100%" }}>
      {/* 顶部 */}
      {location.pathname !== "/productcenter" ? <Head /> : null}
      {/* 页面 */}
      <Switch>
        {/* 导航栏路由注册 */}
        {tabData.map((item, index) => {
          if (item.children.length > 0) {
            return item.children.map((data, inx) => {
              return (
                <Route
                  exact
                  key={inx}
                  path={data.path}
                  component={loadCmpt(data.component)}
                ></Route>
              );
            });
          } else {
            return (
              <Route
                exact
                key={index}
                path={item.path}
                component={loadCmpt(item.component)}
              ></Route>
            );
          }
        })}
        {/* 其他页面路由注册 */}
        {pageData.map((item) => {
          return (
            <Route
              exact
              key={item.path}
              path={item.path}
              component={loadCmpt(item.component)}
            ></Route>
          );
        })}
        <Route path="/" component={Index}></Route>
      </Switch>
      {/* 底部 */}
      {location.pathname !== "/productcenter" ? <Foot /> : null}
    </div>
  );
}

export default App;
