// 储存当前页面所有ID值，防止页面刷新丢失报错
export const _setPagesID = (list) => {
    localStorage.setItem('pagesID',JSON.stringify(list));
}
// 取出当前页面ID值
export const _getPagesID = () => {
    return localStorage.getItem('pagesID')
}
// 储存当前页面的KEY，防止页面刷新找不到
export const _setPagesKey = (key) => {
    localStorage.setItem('pagesKey',key);
}
// 取出当前页面KEY值
export const _getPagesKey = () => {
    return localStorage.getItem('pagesKey')
}
