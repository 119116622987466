import { Fragment } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = (props) => {
    //loading图标的大小
    const { size } = props

    const antIcon = <LoadingOutlined style={{ fontSize: size ? size: 24 }} spin />
    return (
        <Fragment>
            <Spin indicator={antIcon} />
        </Fragment>
    )
}
export default Loading