/*
    企业目标组件
*/
import { useReactive} from 'ahooks'
// 组件样式
import css from '@/assets/style/pages/index/target.less'
import grow from '@/assets/img/grow.png'
import upefficiency from '@/assets/img/upefficiency.png'
import innovate from '@/assets/img/innovate.png'
const Target = () => {
  const state = useReactive({
    textList: [
      { id: 1, title: '创新：', text: '增强企业、创新能力', imgurl: innovate},
      { id: 2, title: '提效：', text: '提高企业、运营效率', imgurl: upefficiency},
      { id: 3, title: '增长：', text: '提升企业、业绩增长', imgurl: grow},
    ],
  })
    return(
        <div className={`${css.targetWrap}`} id="target">
          <div className={css.textBox}>
            <div className={css.text}>
              <h2 className={css.texttitle}>核心价值观</h2>
              <div className={css.head}>                
                {
                  state.textList.map(item => {
                    return(
                      <p key={item.id} className={css.headcontent}>
                        <img src={item.imgurl} alt="" />
                        <strong>{item.title}</strong>
                        <span>{item.text}</span>
                      </p>
                    )
                  })
                }
              </div>
              <div className={css.content}>
                <p>以质量为生存根本，以服务为品牌增值；</p>
                <p>以客为本，想客之所想，想客之未想；</p>
                <p> 真诚待客，视客如友，诚信为本，卓越服务；</p>
                <p> 专业、务实、高效率、高质量，创一流服务，树品牌形象；</p>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Target