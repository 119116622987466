/*
    服务理念组件
*/
import { useReactive, useMount } from 'ahooks'
import { Fragment } from 'react'

// 组件样式
import css from '@/assets/style/pages/index/coreProduct.less'

// 组件图片
// import product_01 from '@/assets/img/product_01.png'
// import product_02 from '@/assets/img/product_02.png'
// import product_03 from '@/assets/img/product_03.png'
// import product_04 from '@/assets/img/product_04.png'
// import product_05 from '@/assets/img/product_05.png'
// import product_06 from '@/assets/img/product_06.png'
// import product_07 from '@/assets/img/product_07.png'
// import product_08 from '@/assets/img/product_08.png'
import { getHomeInfo } from '@/api/index'

const CoreProduct = () => {
    const state = useReactive({
        product:[]
    })
    const methods = useReactive({
      handleHomeInfo() {
        const params = {
          sectionId: 1,
        }
        getHomeInfo(params).then(res => {
          state.product = res.data
        })
      },
      toDetail(path) {
        window.open(path)
      },
    })
    useMount(() => {
      methods.handleHomeInfo()
    })
    return(
        <div className={css.coreWrap} id="core">
            <div className={`${css.coreTitle}`}>核心产品-科技赋能 数字物流</div>
            {/* <div style={{ display: 'flex', justifyContent: 'center'}}> */}
                <div className={css.coreList}>
                    {
                        state.product.map((item,index) => {
                            return(
                              <div 
                                key={index} 
                                className={`${css.coreBox}`}>
                                <img className={css.coreImg} src={item.img} alt="" />
                                <div className={css.coreDiv} style={{bottom:'0'}}>
                                    <div className={css.coreName}>{item.name}</div>
                                    <div className={css.coreText}>{item.text}</div>
                                    <div className={css.coreBtn} onClick={() => methods.toDetail(item.redirectUrl)}> 了解详情</div>
                                </div>
                              </div>
                            )
                        })
                    }
                </div>
            {/* </div> */}
        </div>
    )
}

export default CoreProduct