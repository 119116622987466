// ahooks
import { Fragment } from "react";
import { useReactive, useMount } from "ahooks";
import store from "@/redux/store";
// 轮播组件
import { Carousel, Menu, Form, Input, Button, message } from "antd";
// 图标
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// 路由
import { useHistory } from "react-router-dom";
// 导航栏数据
import { tabData } from "@/utils/routerData";
// js处理包
import { _setPagesKey, _getPagesKey } from "@/utils/storage";

// 页面样式
import css from "@/assets/style/components/head.less";

// 引入本地图片
import emailBlue from "@/assets/img/email-blue.png";
import phoneBlue from "@/assets/img/phone-blue.png";
import logo from "@/assets/img/logo.png";
// import banner from '@/assets/img/banner.png'
// import { data } from 'browserslist'

import { getBanner, getSysConfig } from "@/api/index";
const { SubMenu } = Menu;

const Head = () => {
  const history = useHistory();
  // 数据
  const state = useReactive({
    // 轮播图列表
    sipList: [],
    // 选中的导航栏
    current: localStorage.getItem("path")
      ? localStorage.getItem("path")
      : "index",
    // 登录/注册状态管理
    flag: {
      // 是否显示登录/注册弹窗
      showMode: false,
      // 登录/注册切换
      modeType: "",
      // 按钮文字
      modeText: "",
    },
    website: {},
    phoneObj: {},
  });
  // 方法
  const methods = useReactive({
    getScroll() {},
    // 表单输入
    onFinish(values) {
      // console.log(values)
    },
    // 跳转方法
    skipPages(path, key, index) {
      // 导航栏标识
      // state.current = key ? key : path
      state.current = path;
      if (path.indexOf("/") < 0) {
        message.info("暂未开放");
        return;
      }
      localStorage.setItem("path", path);
      // 储存导航栏标识
      _setPagesKey(state.current);
      // 判断 index 是否有值
      if (index !== undefined) {
        /*
            判断点击后页面是否滑动
            isSlide：true/false
        */
        if (tabData[key].children[index].state.isSlide) {
          if (tabData[key].children[index].state.id) {
            // 携带参数跳转
            history.push(path, {
              id: tabData[key].children[index].state.id,
            });
            let time = setInterval(() => {
              if (
                document.getElementById(
                  `${tabData[key].children[index].state.id}`
                )
              ) {
                // 定位到该 id 中的元素
                document
                  .getElementById(`${tabData[key].children[index].state.id}`)
                  .scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  });
                clearInterval(time);
              }
            });
          } else {
            // 不携带参数跳转
            history.push(path);
          }
        } else {
          let type = tabData[key].children[index].state.pageType;
          let data = tabData[key].children[index].state.inx;
          localStorage.setItem("inx", data);
          store.dispatch({ type, data });
          // 不携带参数跳转
          history.push(path);
        }
      } else {
        // 不携带参数跳转
        history.push(path);
      }
    },
    getBanner() {
      getBanner().then((res) => {
        state.sipList = res.data;
      });
    },
    toDetail(path) {
      window.open(path);
    },
    handleSysConfig() {
      const params = {
        type: 2,
      };
      getSysConfig(params).then((res) => {
        for (let item of res.data) {
          if (!item.KeyName.indexOf("邮箱")) {
            state.website = item;
          } else if (!item.KeyName.indexOf("电话")) {
            state.phoneObj = item;
          }
        }
      });
    },
  });
  // 刷新获取key值
  useMount(() => {
    // state.current = _getPagesKey() ? _getPagesKey() : 'index';
    methods.getBanner();
    methods.handleSysConfig();
  });
  return (
    <div className={css.topBox}>
      {/* 轮播 autoplay */}
      <Carousel autoplay className={css.sipWrap}>
        {state.sipList.map((item, index) => {
          return (
            <Fragment key={index}>
              <div
                className={css.swiperBox}
                style={{
                  backgroundImage: `url(${item.ImgUrl})`,
                  backgroundColor: "#f5f5f5",
                }}
              >
                {/* 文字 */}
                <div
                  className={css.sipDiv}
                  style={{ opacity: item.Title || item.SubTitle ? 1 : 0 }}
                >
                  <div className={css.sipDescribe}>
                    <div className={css.sipText}>{item.Title}</div>
                    <div className={css.English}>{item.SubTitle}</div>
                    <div
                      className={css.sipBtn}
                      onClick={() => methods.toDetail(item.RedirectUrl)}
                    >
                      {" "}
                      了解详情
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </Carousel>
      {/* 遮罩层及顶部Tab栏 */}
      <div className={css.topDiv}>
        {/* 设置居中 */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* 登录/注册 */}
          <div className={css.topView}>
            {/* 左边联系方式 */}
            <div className={css.leftBox}>
              {/* 邮箱 */}
              <div className={css.leftDiv}>
                <img className={css.topIcon} src={emailBlue} alt=""></img>
                <div>{state.website.KeyValue}</div>
              </div>
              {/* 电话 */}
              <div className={css.leftDiv} style={{ marginLeft: "30px" }}>
                <img className={css.topIcon} src={phoneBlue} alt=""></img>
                <div>{state.phoneObj.KeyValue}</div>
              </div>
            </div>
            {/* 右边登录注册 */}
            <div
              className={css.rightBox}
              style={{ display: state.flag.showMode ? "flex" : "none" }}
            >
              <div>登录</div>
              <div style={{ backgroundColor: "#0052D9" }}>注册</div>
            </div>
          </div>
        </div>
        {/* 设置居中 */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* Tab栏 */}
          <div className={css.tabBox}>
            {/* logo */}
            <img className={css.logo} src={logo} alt=""></img>
            {/* Tab栏 */}
            <Menu
              mode="horizontal"
              style={{
                background: "transparent",
                border: "0",
                color: "#FFFFFF",
                width: "56%",
              }}
              selectedKeys={[state.current]}
            >
              {tabData.map((item, index) => {
                if (item.children.length > 0) {
                  return (
                    <SubMenu
                      key={index}
                      title={item.name}
                      style={{ color: "#FFFFFF" }}
                    >
                      {item.children.map((children, inx) => {
                        return (
                          <Menu.Item
                            key={children.path}
                            onClick={() =>
                              methods.skipPages(children.path, index, inx)
                            }
                          >
                            {children.name}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item
                      key={index}
                      style={{ margin: "0", color: "#FFFFFF" }}
                      // onClick={() => methods.skipPages('/', 'index')}>
                      onClick={() => methods.skipPages(item.path, index)}
                    >
                      {item.name}
                    </Menu.Item>
                  );
                }
              })}
              {/* <Menu.Item
                key="index"
                style={{ margin: '0', color: '#FFFFFF' }}
                onClick={() => methods.skipPages('/', 'index')}>
                首页
              </Menu.Item>
              {
                tabData.map((item, index) => {
                  
                })
              } */}
            </Menu>
          </div>
        </div>
        {/* 登录/注册 */}
        <div
          style={{
            display: state.flag.showMode ? "flex" : "none",
            justifyContent: "center",
            marginTop: "28px",
          }}
        >
          <div className={css.loginBox}>
            {/* 登录框 */}
            <div className={css.login}>
              <img className={css.logo} src={logo} alt=""></img>
              <Form
                name="normal_login"
                className={css.loginForm}
                initialValues={{
                  remember: true,
                }}
                onFinish={methods.onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "请输入用户名",
                    },
                  ]}
                >
                  <Input
                    id={css.userInp}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="请输入用户名"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请输入密码",
                    },
                  ]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    id={css.passInp}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="请输入密码"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "12px" }}>
                  <div className={css.forget}>忘记密码</div>
                </Form.Item>
                <Form.Item style={{ marginBottom: "0" }}>
                  <Button
                    style={{ backgroundColor: "#0052D9", borderRadius: "6px" }}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    登录
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#0052D9",
                      marginTop: "20px",
                      borderRadius: "6px",
                    }}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    注册
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
