/*
    该文件是用于创建一个为 solution 组件服务的 reducer
    reducer 的本质就是一个函数体
    参数说明：
    preState：之前的状态/值
    action：动作对象
        type：类型
            tax：税务代征
            network：网络货运
        data：数据流
*/

// 初始化默认值
const inState = localStorage.getItem('inx')
export default function solutionReducer (preState = inState,action) {
    const {type,data} = action
    if (!data) {
      return preState = inState
    } else {
      return preState = data
    }
    // switch (type) {
    //     case 'network':
    //         return preState = data
    //     case 'tax':
    //         return preState = data
    //     default:
    //         return preState
    // }
}