import axios from 'axios';
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取banner
export const getBanner = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Banner/GetBannerList`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}
// 获取板块栏目名称
export const getSection = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Section/GetSection?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}

// 获取系统配置
export const getSysConfig = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/SysConfigSet/GetSysConfig?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}

// 获取首页信息
export const getHomeInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Section/GetHomeInfo?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}