import { Tabs } from 'antd';
import {useEffect} from 'react'
import { useReactive } from 'ahooks'
import { useHistory } from "react-router-dom"
import {_setPagesID, _getPagesID, _getNavIndex} from '@/utils/storage'

// 当前页面样式
import css from '@/assets/style/components/tab.less'

const Tab = (props) => {
    const { TabPane } = Tabs;
    const history = useHistory() 
    // 挂载滚动条方法
    useEffect(()=>{
        // 添加滚动方法
        window.addEventListener("scroll", methods.handleScroll)
        methods.handleScroll()
        // 获取页面所有元素id
        let doc = window.document.all;
        // 储存ID
        let docID = []
        // 遍历每一个对象
        for (let i=0 ;i<doc.length;i++){
            let _dc = doc[i];
            // 获取的每个id的node节点
            let id = _dc.getAttributeNode("id");
            if(id){
                // 当前标签的id的值
                let id = _dc.getAttribute("id");
                if(id !== 'root' && id !== 'normal_login' && id !== '_3o_vpLRyhfz3TirBxUNu9O' && id !== '_2P2uOu6zOyHGHKXIyY2uqm' && id !== 'rc-menu-uuid-72825-1-1-popup' && id !== 'rc-menu-uuid-72825-1-0-popup'){
                    docID.push(id)
                }
            }
        }
        // 储存当前页面元素DI
        _setPagesID(docID)
        // 卸载滚动条方法
        return () => {
            window.removeEventListener("scroll", methods.handleScroll)
        }
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    // 组件传进来的数据
    const {tabData} = props
    // 数据
    const state = useReactive({
        // 已选中的Tab栏
        tabIndex:0,
        // tab栏是否定位
        isFixed:false,
        // 获取到的页面元素id
        docID:_getPagesID()
    })
    // 方法
    const methods = useReactive({
        // Tab点击事件
        tabClick(index,item) {
            state.tabIndex = index
            if(item.id){
                /*
                    设置锚点并滚动到当前位置
                    behavior：定义动画过渡效果， "auto"或一种 "smooth" 。默认为 "auto"
                    block：定义垂直方向的目标，  "start",  "center",  "end", 或一个 "nearest"。默认为 "start"。
                    inline：定义水平方向的卫星，  "start",  "center",  "end", 或一个 "nearest"。默认为 "nearest"。
                */
                document.getElementById(`${item.id}`).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }
        },
        // 监听滚动条
        handleScroll(){
            let scrollY = window.scrollY;
            if(scrollY <= 770){
                state.isFixed = false
            }else{
                state.isFixed = true
                if(history.location.pathname === '/index'){
                    if(scrollY >= 454 && scrollY < 887){
                        methods.tabClick(0,state.docID[0])
                    }else if(scrollY >= 887 && scrollY < 1442){
                        methods.tabClick(1,state.docID[1])
                    }else if(scrollY >= 1443 && scrollY < 2227){
                        methods.tabClick(2,state.docID[2])
                    }else if(scrollY >= 2227 && scrollY < 2737){
                        methods.tabClick(3,state.docID[3])
                    }else if(scrollY >= 2737){
                        methods.tabClick(4,state.docID[4])
                    }
                } else if (history.location.pathname === '/about') {
                    if(scrollY >= 438 && scrollY < 874){
                        methods.tabClick(0,state.docID[0])
                    }else if(scrollY >= 874 && scrollY < 1428){
                        methods.tabClick(1,state.docID[1])
                    }else if(scrollY >= 1428 && scrollY < 1862){
                        methods.tabClick(2,state.docID[2])
                    }else if(scrollY >= 1862 && scrollY < 2310){
                        methods.tabClick(3,state.docID[3])
                    }else if(scrollY >= 2310){
                        methods.tabClick(4,state.docID[4])
                    }
                }
            }
        }
    })
    return (
        // tab栏外包围
        <div className={css.tabWrap} style={state.isFixed === true?{position:'sticky',top:'0',zIndex:'999'}:null}>
            {/* 居中 */}
            <div className={css.tabBox}>
                {
                    tabData.map((item, index) => {
                        return (
                            <div
                                className={`${css.tabDiv}`}
                                key={index}
                                onClick={() => methods.tabClick(index,item)}>
                                    {item.name}
                                    {
                                        state.tabIndex === index?<div className={css.activity}></div>:null
                                    }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Tab