import axios from 'axios';
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取荣誉资质及合作伙伴
export const getBannerOtherList = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Banner/GetBannerOtherList?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}

// 获取招聘信息
export const getRecruitInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Section/GetRecruitInfo`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}

// 获取新闻资讯
export const getSectionContent = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Section/GetNews?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}

// 获取新闻资讯
export const getHotNews = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Section/GetHotNews?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}

// 获取新闻资讯详情
export const getNewsDetial = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Section/GetNewsDetial?${_paramsToQueryString(params)}`).then(res => {
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    })
  })
}