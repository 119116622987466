import Loadable from 'react-loadable'
import Loading from '@/components/loading'

//用来动态加载react组件的
const LoadCmpt = cmpt => {
    return Loadable({
        loading: () => <Loading></Loading>,
        loader: () => import(`@/${cmpt}`)
    })
}
export default LoadCmpt