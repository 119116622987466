/*
    新闻模块组件
*/
import { useReactive, useMount } from 'ahooks'
// 路由
import { useHistory } from "react-router-dom"
// 图标
import {DoubleRightOutlined} from '@ant-design/icons'
import { getSectionContent, getHotNews } from '@/api/about'
// 组件样式
import css from '@/assets/style/pages/index/newsBox.less'
import { Fragment } from 'react'


const NewsList = () => {
    const history = useHistory()
    const state = useReactive({
        newsList:[],
        hotNewsList: [],
    })
    const methods = useReactive({
        // 跳转新闻列表
        skipPages(){
            history.push('/newsInformation')
        },
        getData() {
          const params = {
            pageIndex: 1,
            PageSize: 10
          }
          getSectionContent(params).then(res => {
            state.newsList = res.data.list
          })
          getHotNews({num: 10}).then(res => {
            state.hotNewsList = res.data.list
          })
        },
        toDetail(newsId) {
          history.push('/newsInfo', {
            id: newsId
          })
        },
    })
    useMount(() => {
      methods.getData();
    })
    return(
        <div className={css.newsWrap} id="news">
          <div className={`${css.newsTitle}`}>新闻资讯</div>
            <div className={css.listBox}>
              {/* 左边图片 */}
              <div className={`${css.listLeft}`}>
                  {   
                      state.hotNewsList.map((item,index) => {
                          if(index < 4) {
                            return(
                              <div className={css.leftBox} style={{backgroundImage: `url(${item.imgUrl})`}} key={index} onClick={()=>methods.toDetail(item.newsId)}>
                                  {/* <img className={css.newsImg} src={item.imgUrl} alt="" /> */}
                                  <div className={css.newsText}>{item.title}</div>
                              </div>
                            )
                          } else {
                            return <Fragment key={index}></Fragment>
                          }
                      })
                  }
              </div>
              {/* 右边列表 */}
              <div className={`${css.listRight}`}>
                    <div className={css.rightTitle} onClick={methods.skipPages}>
                        <div>实时新闻</div>
                        <div>
                        <DoubleRightOutlined />
                        </div>
                    </div>
                    <div className={css.newList}>
                      {
                        state.newsList.map((item,index) => {
                          return(
                            <div className={css.rightBox} key={index} onClick={()=>methods.toDetail(item.newsId)}>
                                <div className={css.newsDate}>
                                    <div className={css.newsDay}>{item.day}</div>
                                    <div className={css.newsYears}>{item.years}</div>
                                </div>
                                <div className={css.rightDiv}>
                                    <div className={css.rightLabel}>{item.title}</div>
                                    <div className={css.rightText}>{item.text}</div>
                                </div>
                            </div>
                          )
                        })
                      }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsList