/*
    服务理念组件
*/
import { useReactive, useMount } from 'ahooks'
import { getSysConfig } from '@/api/index'
// 组件样式
import css from '@/assets/style/pages/index/serveConcept.less'

const ServeConcept = () => {
    const state = useReactive({
        numData:[]
    })
    const methods = useReactive({
      handleSysConfig() {
        const params = {
          type: 1
        }
        getSysConfig(params).then(res => {
          state.numData = res.data.filter(item => item.KeyName !== '官⽹');
        })
      },
    })
    useMount(() => {
      methods.handleSysConfig()
    })
    return(
        <div className={`${css.serveWrap}`} id="serve">
            {/* 数字化 */}
            <div style={{ display: 'flex', justifyContent: 'center'}}>
                <div className={css.serveDiv}>
                    <div className={css.serveList}>
                        {
                            state.numData.map((item,index) => {
                                return(
                                    <div className={css.listBox} key={index}>
                                        <div className={css.listTitle}>{item.KeyValue}</div>
                                        <div className={css.listName}>{item.KeyName}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServeConcept